
















































































































































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { debounce, sumBy } from 'lodash'
import { api, framebus } from 'plugins'
import {
  endpoints,
  showError,
  toCamelCase,
  frameBusEvent,
  urlPath,
  scrollBottom,
  convertToWidthCharacter
} from 'utils'

import {
  LoaderComponent,
  MultiLevelList,
  StepButton,
  MultiLevelItem,
  AddButton,
  SingleDetailItem,
  DateRange,
  PreviewInvoiceDialog,
  ChooseNumber,
  ConfirmDelete
} from 'components'

import GrowerDialog from './GrowerDialog.vue'

const PackingImport = defineComponent({
  components: {
    LoaderComponent,
    MultiLevelList,
    StepButton,
    MultiLevelItem,
    AddButton,
    SingleDetailItem,
    DateRange,
    PreviewInvoiceDialog,
    ChooseNumber,
    ConfirmDelete,
    GrowerDialog
  },
  setup(props, { root }) {
    const { $toast, $route, $router, $store } = root
    const panels = ref([])
    const isLoading = ref(false)
    const isImporting = ref(false)
    const isShowBottomSheet = ref(false)
    const showDelete = ref(false)
    const selectedPackingImportId = ref<number | null>(null)
    const showNumberDialog = ref(false)
    const isOpenGrowerDialog = ref(false)
    const numberDialogTitle = ref('')
    const numberDialogValue = ref(0)
    const numberDialogKey = ref('')
    const packingImportList = ref<any[]>([])
    const packingImportSearched = ref<any[]>([])
    const packingImportRaw = ref<any[]>([])
    const isAllDetailSent = ref(false)
    const checkAll = ref(false)
    const searchInfo = ref('')
    const selectedGrowerId = ref(0)
    const auctionDate = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.date || moment(new Date()).format('YYYY-MM-DD')
    )

    const nextAuctionDate = ref('')
    const date = ref('')

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        date.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }
    const modal = ref(false)
    const replaceUrl = (): void => {
      $router
        .replace({
          query: {
            auction_date: date.value,
            called: 'true'
          }
        })
        .catch((e) => {
          console.log(e)
        })
      const latestSelectedDates = {
        auctionDate: date.value.toString()
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
    }
    const updateCheckAll = (isSearching: boolean) => {
      if (isSearching) {
        checkAll.value = false
        packingImportSearched.value = packingImportSearched.value.map((grower: any) => {
          // eslint-disable-next-line no-param-reassign
          grower.isChecked = false
          return {
            ...grower
          }
        })
      } else {
        packingImportSearched.value = packingImportSearched.value.map((grower: any) => {
          if (grower.needImport) {
            // eslint-disable-next-line no-param-reassign
            grower.isChecked = checkAll.value
          }
          return {
            ...grower
          }
        })
      }
    }
    const getHistoryText = (packing: any): string => {
      const propsCompare = ['item', 'variety', 'quality', 'size', 'unit', 'quantity', 'boxType']
      const historyArray: Array<string> = []
      const { history } = packing
      Object.keys(history).forEach((key: string) => {
        if (key === 'quantity' && packing[key] !== history[key]) {
          historyArray.push(`${history[key]} → ${packing[key]}`)
        } else if (propsCompare.includes(key)) {
          if (packing[key].id && packing[key].id !== history[key].id) {
            historyArray.push(`${history[key].name} → ${packing[key].name}`)
          }
        }
      })
      return historyArray.join(', ')
    }
    const selectGrower = () => {
      checkAll.value = packingImportSearched.value
        .filter((grower: any) => !grower.isImported)
        .every((grower: any) => grower.isChecked)
    }
    const handleSearchInput = debounce(() => {
      packingImportSearched.value = packingImportList.value.filter((grower: any) =>
        grower.growerName
          .toLowerCase()
          .startsWith(convertToWidthCharacter(searchInfo.value, 'full').toLowerCase())
      )
      updateCheckAll(true)
    }, 500)
    const getPackingImport = async () => {
      isLoading.value = true
      replaceUrl()
      try {
        const { data } = await api.get(`${endpoints.PACKING_IMPORT}?auction_date=${date.value}`)
        packingImportRaw.value = toCamelCase(data)
        packingImportList.value = packingImportRaw.value.map((grower: any) => {
          // eslint-disable-next-line no-param-reassign
          grower.growerName = grower.grower.name
          // eslint-disable-next-line no-param-reassign
          grower.totalBoxes = sumBy(grower.packingImports, 'boxes')
          // eslint-disable-next-line no-param-reassign
          grower.isCheck = false
          // eslint-disable-next-line no-param-reassign
          grower.needImport = grower.packingImports.some((e: any) => !e.isImported)
          return grower
        })
        handleSearchInput()
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        isLoading.value = false
      }
    }

    const createOrEditPackingImport = (
      growerId: number | null,
      packingImportId: number | null = null
    ) => {
      if (packingImportId) {
        packingImportList.value.forEach((grower: any) => {
          grower.packingImports.forEach((packingImport: any) => {
            if (packingImport.id === selectedPackingImportId.value)
              // eslint-disable-next-line no-param-reassign
              growerId = packingImport.grower.id
          })
        })
      }
      selectedGrowerId.value = growerId ?? selectedGrowerId.value
      $router
        .push({
          name: urlPath.PACKING_IMPORT_FORM.name,
          params: {
            detailId: packingImportId ? packingImportId.toString() : 'create'
          },
          query: {
            auction_date: date.value,
            grower_id: selectedGrowerId.value.toString(),
            called: 'true'
          }
        })
        .catch((e) => {
          console.log(e)
        })
      selectedPackingImportId.value = null
      selectedGrowerId.value = 0
    }

    const deletePackingImport = async (): Promise<boolean> => {
      try {
        await api.delete(`${endpoints.PACKING_IMPORT}${selectedPackingImportId.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      }
      return false
    }
    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deletePackingImport()
      }
      if (success) {
        getPackingImport()
      }
      showDelete.value = false
      selectedPackingImportId.value = null
      selectedGrowerId.value = 0
    }
    const getClassPackingImport = (packing: any) => {
      let classPackingImport = ''
      if (packing.isImported) {
        classPackingImport = 'packing-import-imported'
      } else if (packing.history && !packing.isImported) {
        classPackingImport = 'packing-import-update'
      } else {
        classPackingImport = 'packing-import-not-imported'
      }
      return classPackingImport
    }
    const importToPackingResult = async (): Promise<void> => {
      const ids = [] as Array<any>
      const growerIds = packingImportSearched.value
        .filter((grower: any) => grower.isChecked)
        .map((grower: any) => {
          grower.packingImports.forEach((packingImport: any) => {
            ids.push(packingImport.id)
          })
          return grower.grower.id
        })
      if (!ids.length) {
        $toast.error(root.$t('packing_import.msg.list_empty'))
        return
      }
      isImporting.value = true
      try {
        const param = {
          auction_date: date.value,
          grower_ids: growerIds,
          ids
        }
        await api.post(`${endpoints.PACKING_IMPORT}import`, param)
        // update data on FE
        packingImportList.value
          .filter((grower: any) => growerIds.includes(grower.grower.id))
          .forEach((grower: any) => {
            // eslint-disable-next-line no-param-reassign
            grower.needImport = false
            // eslint-disable-next-line no-param-reassign
            grower.isChecked = false
            // eslint-disable-next-line no-param-reassign
            grower.packingImports.forEach((packing: any) => {
              // eslint-disable-next-line no-param-reassign
              packing.isImported = true
            })
          })
        handleSearchInput()
        $toast.success(root.$t('packing_import.msg.import_success'))
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        isImporting.value = false
      }
    }

    const reload = () => {
      getPackingImport()
      scrollBottom()
    }

    onMounted(async () => {
      if (!$store.state.common.latestSelectedDates.auctionDate) await getNextAuctionDate()
      else date.value = auctionDate.value

      await getPackingImport()
    })
    return {
      modal,
      date,
      isLoading,
      packingImportList,
      getPackingImport,
      isShowBottomSheet,
      selectedPackingImportId,
      handleSearchInput,
      showDelete,
      showNumberDialog,
      numberDialogTitle,
      numberDialogKey,
      numberDialogValue,
      isAllDetailSent,
      checkAll,
      searchInfo,
      packingImportSearched,
      updateCheckAll,
      selectGrower,
      confirmDelete,
      getClassPackingImport,
      importToPackingResult,
      getHistoryText,
      isOpenGrowerDialog,
      createOrEditPackingImport,
      isImporting,
      panels,
      reload
    }
  }
})

export default PackingImport

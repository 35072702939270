
















































































































































































































































































































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  computed,
  reactive,
  ComputedRef
} from '@vue/composition-api'
import {
  endpoints,
  query,
  showError,
  toCamelCase,
  urlPath,
  groupMultiLevel,
  groupTwoLevel,
  mappingStock,
  convertToWidthCharacter,
  frameBusEvent,
  PACKING_RESULT_DES,
  ORDER_DES
} from 'utils'
import { useExpansion } from 'hooks'
import { api, framebus, moment } from 'plugins'
import {
  OrderDetail,
  Variety,
  Size,
  Quality,
  Assign,
  HarvestResult,
  PackingResult,
  MultiLevelCustomer,
  MultiLevelProduct,
  MultiLevelQualitySize,
  Product
} from 'typings'
import {
  LoaderComponent,
  MultiLevelList,
  StepButton,
  OrderDialog,
  MultiLevelItem,
  MultiLevelListJsx,
  SettingViewDialog,
  DateRange,
  SingleDetailItem,
  ConfirmDelete
} from 'components'
import { debounce, delay, sumBy, orderBy } from 'lodash'
import AddButton from '@/components/AddButton/index.vue'
import SelectPackingDialog from './SelectPackingDialog.vue'

interface ShowByOption {
  key: string
  value: string
}

interface Master {
  id: number
  name: string
}

interface SelectedLevel {
  rootIndex: number | null
  firstIndex: number | null
  secondIndex: number | null
}

interface ActionOptions {
  defaultItem: number | null
  defaultVariety: number | null
  defaultSize: number | null
  defaultQuality: number | null
}

interface PackingOrderDetail extends OrderDetail {
  customerName: string
}

interface PKMultiLevelQualitySize extends MultiLevelQualitySize {
  item: Master | null
  variety: Master | null
}

interface SetUp {
  auctionDate: Ref<string>
  dateRange: Ref<Array<string>>
  packedDateStart: Ref<string>
  packedDateEnd: Ref<string>
  auctionDateModal: Ref<boolean>
  packedDateStartModal: Ref<boolean>
  packedDateEndModal: Ref<boolean>
  goToDate: (enableScrollToPreviousPosition: boolean) => void
  packingResults: Ref<Array<PackingResult>>
  goToPackingResultDetail: (
    detailId: number | null,
    defaultItem: number | null,
    defaultVariety: number | null,
    defaultSize: number | null,
    defaultQuality: number | null,
    orderDetailIds: string | null,
    isDuplicated: boolean | null,
    packingImportId: number | null
  ) => void
  loading: Ref<boolean>
  showBy: Ref<string>
  showByOptions: Ref<Array<ShowByOption>>
  actionOptions: Ref<ActionOptions>
  byPackingResultPanelState: ComputedRef<Record<string, unknown>>
  byProductPanelState: ComputedRef<Record<string, unknown>>
  byOrderPanelState: ComputedRef<Record<string, unknown>>
  byCustomerPanelState: ComputedRef<Record<string, unknown>>
  packingResultsByHistory: ComputedRef<Array<PackingResult>>
  packingResultList: ComputedRef<Array<MultiLevelProduct>>
  orderDetailsByProductList: ComputedRef<Array<MultiLevelProduct>>
  orderDetailsByCustomerList: ComputedRef<Array<MultiLevelCustomer>>
  productsList: ComputedRef<Array<MultiLevelProduct>>
  updatePanelState: (mutation: string, panelState: Record<string, unknown>) => void
  openActionSheet: (
    rootIndex: number,
    firstIndex: number | null,
    secondIndex: number | null
  ) => void
  updatePackedDateStart: () => void
  expandAll: () => void
  collapseAll: () => void
  updateShowBy: () => Promise<void>
  OPTION_KEY_PACKING_RESULT: string
  OPTION_KEY_PRODUCT: string
  OPTION_KEY_ORDER: string
  OPTION_KEY_CUSTOMER: string
  OPTION_KEY_HISTORY: string
  isShowActionOptions: Ref<boolean>
  handleSearchInput: (value: string) => void
  searchInfo: Ref<string>
  selectedPackingResultId: Ref<number | null>
  isShowSetting: Ref<boolean>
  isShowByBox: Ref<boolean>
  isShowTotal: Ref<boolean>
  isShowRemaining: Ref<boolean>
  updateSetting: (
    isShowTotalValue: boolean,
    isShowByBoxValue: boolean,
    isShowRemainingValue: boolean
  ) => void
  setDate: (start: string, end: string) => void
  getNextAuctionDate: () => void
  treeviewLevel: Ref<number>
  deleteDetail: () => Promise<boolean>
  confirmDelete: (action: string) => Promise<void>
  showDeleteDialog: Ref<boolean>
  showSelectPacking: Ref<boolean>
  goToUpdatePacking: (packingImportId: number, growerId: number) => void
  editPacking: () => void
  getPackingImports: () => Array<any>
  getGrowerInfo: (item: any) => string
}

const PackingResultDate = defineComponent({
  components: {
    LoaderComponent,
    AddButton,
    MultiLevelList,
    StepButton,
    OrderDialog,
    MultiLevelItem,
    MultiLevelListJsx,
    SettingViewDialog,
    DateRange,
    SingleDetailItem,
    ConfirmDelete,
    SelectPackingDialog
  },
  props: {},
  setup(props, { root }): SetUp {
    const { $router, $toast, $route, $store } = root
    const OPTION_KEY_PACKING_RESULT = 'packing_result'
    const OPTION_KEY_PRODUCT = 'product'
    const OPTION_KEY_ORDER = 'order'
    const OPTION_KEY_CUSTOMER = 'customer'
    const OPTION_KEY_HISTORY = 'history'
    const isFromCalendar = $route.query.fromCalendar

    const auctionDateModal = ref(false)
    const packedDateStartModal = ref(false)
    const packedDateEndModal = ref(false)
    const showDeleteDialog = ref(false)
    const showSelectPacking = ref(false)
    // check in store, if data available then get auction_date, packing_range from store
    const auctionDate = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.aucDate || moment(new Date()).format('YYYY-MM-DD')
    )
    const packedDateStart = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[0]
        : $route.query.pkDateStart || moment(new Date()).format('YYYY-MM-DD')
    )
    const packedDateEnd = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[1]
        : $route.query.pkDateEnd || moment(new Date()).format('YYYY-MM-DD')
    )
    // if go to page from calendar page, get date from url
    if (isFromCalendar) {
      auctionDate.value = $route.query.aucDate
      packedDateStart.value = $route.query.pkDateStart
      packedDateEnd.value = $route.query.pkDateEnd
    }
    // const packedDateStart = ref<string | any>(
    //   $route.query.startDate ? $route.query.pkDateStart : moment(new Date()).format('YYYY-MM-DD')
    // )
    // const packedDateEnd = ref<string | any>(
    //   $route.query.endDate ? $route.query.pkDateEnd : moment(new Date()).format('YYYY-MM-DD')
    // )
    const dateRange = ref(['', ''])
    dateRange.value = [packedDateEnd.value, packedDateStart.value]
    const isShowActionOptions = ref(false)
    const actionOptions = ref<ActionOptions>({
      defaultItem: null,
      defaultVariety: null,
      defaultSize: null,
      defaultQuality: null
    })
    const selectedPackingResultId = ref(null)
    const selectedLevel = reactive<SelectedLevel>({
      rootIndex: null,
      firstIndex: null,
      secondIndex: null
    })
    const packingResults = ref<Array<PackingResult>>([])
    const harvestResults = ref<Array<HarvestResult>>([])
    const assignments = ref<Array<Assign>>([])
    const orderDetails = ref<Array<PackingOrderDetail>>([])
    const varieties = ref<Array<Variety>>([])
    const sizes = ref<Array<Size>>([])
    const qualities = ref<Array<Quality>>([])
    const byPackingResultPanelState = computed(
      () => $store.state.packingResult.byPackingResultPanelState
    )
    const byProductPanelState = computed(() => $store.state.packingResult.byProductPanelState)
    const byOrderPanelState = computed(() => $store.state.packingResult.byOrderPanelState)
    const byCustomerPanelState = computed(() => $store.state.packingResult.byCustomerPanelState)
    const showByValue = computed(() => $store.state.packingResult.showBy)
    const showBy = ref(showByValue.value || OPTION_KEY_PACKING_RESULT)
    const treeviewLevel = ref(
      Number(
        $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
      ) || 4
    )

    const loading = ref(false)
    const showByOptions = ref([
      {
        key: OPTION_KEY_PACKING_RESULT,
        value: String(root.$t('packing_result.date.show_by_options.packing_result'))
      },
      // Unnecessary, calculate value of stepBtn is incorrect
      // {
      //   key: OPTION_KEY_PRODUCT,
      //   value: String(root.$t('packing_result.date.show_by_options.product'))
      // },
      {
        key: OPTION_KEY_ORDER,
        value: String(root.$t('packing_result.date.show_by_options.order'))
      },
      {
        key: OPTION_KEY_CUSTOMER,
        value: String(root.$t('packing_result.date.show_by_options.customer'))
      },
      {
        key: OPTION_KEY_HISTORY,
        value: String(root.$t('packing_result.date.show_by_options.history'))
      }
    ])
    const { searchStr } = $route.query
    const searchInfo = ref((searchStr as string) || '')
    const nextAuctionDate = ref('')

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        auctionDate.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getAssignValuePacking = (packingId: number, property: string) => {
      const assignmentsFilter = assignments.value.filter(
        (assignment: Assign) => assignment.packingResult === packingId
      )
      return sumBy(assignmentsFilter, property)
    }

    const getPackingResultByDate = async (
      pkDateStart: string,
      pkDateEnd: string,
      aucDate: string
    ): Promise<void> => {
      const queryStr = query.buildQuery({
        start_date: pkDateStart,
        end_date: pkDateEnd
      })
      const data = await Promise.all([
        api.get(`${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStr}`),
        api.get(`${endpoints.HARVEST_RESULT}?${queryStr}`),
        api.get(`${endpoints.ASSIGNMENTS}?auction_date=${aucDate}`),
        api.get(
          `${endpoints.ORDER_DETAILS}?auction_date=${aucDate}&order_by_customer=${
            showBy.value === OPTION_KEY_CUSTOMER
          }`
        )
      ])
      const [
        { data: packingResultsData },
        { data: harvestResultsData },
        { data: assignmentsData },
        { data: orderDetailsData }
      ] = data

      packingResults.value = toCamelCase(packingResultsData)
      harvestResults.value = toCamelCase(harvestResultsData)
      assignments.value = toCamelCase(assignmentsData)
      orderDetails.value = toCamelCase(orderDetailsData)

      packingResults.value = packingResults.value.map((packingResult: PackingResult) => {
        return {
          ...packingResult,
          packingStems: packingResult.totalStems,
          packingBoxes: packingResult.boxes,
          assignBoxes: getAssignValuePacking(packingResult.id, 'boxes'),
          assignStems: getAssignValuePacking(packingResult.id, 'stems')
        }
      })
    }

    const getProducts = async (
      pkDateStart: string,
      pkDateEnd: string,
      aucDate: string
    ): Promise<void> => {
      const queryStr = query.buildQuery({
        start_date: pkDateStart,
        end_date: pkDateEnd
      })
      const data = await Promise.all([
        api.get(`${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStr}`),
        api.get(`${endpoints.HARVEST_RESULT}?${queryStr}`),
        api.get(`${endpoints.ASSIGNMENTS}?auction_date=${aucDate}`),
        api.get(`${endpoints.ORDER_DETAILS}?auction_date=${aucDate}&order_by_customer=${true}`),
        api.get(`${endpoints.VARIETIES}?with_image=false`),
        api.get(endpoints.SIZES),
        api.get(endpoints.QUALITIES)
      ])
      const [
        { data: packingResultsData },
        { data: harvestResultsData },
        { data: assignmentsData },
        { data: orderDetailsData },
        { data: varietiesData },
        { data: sizesData },
        { data: qualitiesData }
      ] = data

      varieties.value = toCamelCase(varietiesData)
      sizes.value = toCamelCase(sizesData)
      qualities.value = toCamelCase(qualitiesData)
      packingResults.value = toCamelCase(packingResultsData)
      harvestResults.value = toCamelCase(harvestResultsData)
      assignments.value = toCamelCase(assignmentsData)
      orderDetails.value = toCamelCase(orderDetailsData)
    }

    const getOrdersByDate = async (
      aucDate: string,
      pkDateStart: string,
      pkDateEnd: string
    ): Promise<void> => {
      const queryStr = query.buildQuery({
        start_date: pkDateStart,
        end_date: pkDateEnd
      })
      const data = await Promise.all([
        api.get(`${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStr}`),
        api.get(`${endpoints.HARVEST_RESULT}?${queryStr}`),
        api.get(`${endpoints.ORDER_DETAILS}?auction_date=${aucDate}&order_by_customer=${true}`)
      ])
      const [
        { data: packingResultsData },
        { data: harvestResultsData },
        { data: orderDetailsData }
      ] = data

      packingResults.value = toCamelCase(packingResultsData)
      harvestResults.value = toCamelCase(harvestResultsData)
      orderDetails.value = toCamelCase(orderDetailsData).map((orderDetail: OrderDetail) => {
        return {
          ...orderDetail,
          orderedSpecialStems: orderDetail.boxes ? 0 : orderDetail.stems,
          customer: orderDetail.order.customer,
          customerName: `[${orderDetail.order.customer.code}] ${orderDetail.order.customer.shortName}`,
          orderStems: orderDetail.stems,
          orderBoxes: orderDetail.boxes
        }
      })
    }

    const getDataByDate = async (
      aucDate: string,
      pkDateStart: string,
      pkDateEnd: string
    ): Promise<void> => {
      loading.value = true
      try {
        if (showBy.value === OPTION_KEY_PACKING_RESULT || showBy.value === OPTION_KEY_HISTORY) {
          await getPackingResultByDate(pkDateStart, pkDateEnd, aucDate)
        } else if (showBy.value === OPTION_KEY_PRODUCT) {
          await getProducts(pkDateStart, pkDateEnd, aucDate)
        } else {
          await getOrdersByDate(aucDate, pkDateStart, pkDateEnd)
        }
      } catch (e) {
        console.log(e)
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        auctionDateModal.value = false
        packedDateStartModal.value = false
        packedDateEndModal.value = false
        loading.value = false
      }
    }

    const packingResultsByHistory = computed(() => {
      if (!searchInfo.value) {
        return packingResults.value
      }
      const packingResultsOrderById = orderBy(packingResults.value, 'id', 'desc')
      const filterByStartsWith = packingResultsOrderById.filter((packingResult: PackingResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = packingResult
        return (
          item.name?.toLowerCase().startsWith(text) ||
          variety.name?.toLowerCase().startsWith(text) ||
          size.name?.toLowerCase().startsWith(text) ||
          quality.name?.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = packingResultsOrderById.filter((packingResult: PackingResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = packingResult
        return (
          !(
            item.name?.toLowerCase().startsWith(text) ||
            variety.name?.toLowerCase().startsWith(text) ||
            size.name?.toLowerCase().startsWith(text) ||
            quality.name?.toLowerCase().startsWith(text)
          ) &&
          (item.name?.toLowerCase().includes(text) ||
            variety.name?.toLowerCase().includes(text) ||
            size.name?.toLowerCase().includes(text) ||
            quality.name?.toLowerCase().includes(text))
        )
      })
      return [...filterByStartsWith, ...filterByIncludes]
    })

    const packingResultList = computed(() => {
      const filterByStartsWith = packingResults.value.filter((packingResult: PackingResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = packingResult
        return (
          item.name.toLowerCase().startsWith(text) ||
          variety.name.toLowerCase().startsWith(text) ||
          size.name.toLowerCase().startsWith(text) ||
          quality.name.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = packingResults.value.filter((packingResult: PackingResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = packingResult
        return (
          !(
            item.name.toLowerCase().startsWith(text) ||
            variety.name.toLowerCase().startsWith(text) ||
            size.name.toLowerCase().startsWith(text) ||
            quality.name.toLowerCase().startsWith(text)
          ) &&
          (item.name.toLowerCase().includes(text) ||
            variety.name.toLowerCase().includes(text) ||
            size.name.toLowerCase().includes(text) ||
            quality.name.toLowerCase().includes(text))
        )
      })
      const filterPackingResults = [...filterByStartsWith, ...filterByIncludes]

      const groupPackingResults =
        treeviewLevel.value === 2
          ? groupTwoLevel(
              filterPackingResults,
              [
                'packingStems',
                'packingBoxes',
                'assignedBoxes',
                'assignedStems',
                'assignBoxes',
                'assignStems'
              ],
              ['itemName']
            )
          : groupMultiLevel(
              filterPackingResults,
              [
                'packingStems',
                'packingBoxes',
                'assignedBoxes',
                'assignedStems',
                'assignBoxes',
                'assignStems'
              ],
              ['itemName', 'varietyName', 'qualitySizeName']
            )

      return mappingStock(
        groupPackingResults,
        {
          orderDetails: orderDetails.value
        },
        treeviewLevel.value === 2,
        PACKING_RESULT_DES
      ) as Array<MultiLevelProduct>
    })
    const productsList = computed(() => {
      const productsData = [] as Array<Product>
      varieties.value.forEach((variety: Variety) => {
        if (treeviewLevel.value === 4) {
          const sizeList = sizes.value.filter(
            (size: Size) => size.sizeGroup.id === variety.sizeGroup?.id
          )
          sizeList.forEach((size: Size) => {
            qualities.value.forEach((quality: Quality) => {
              productsData.push({ item: variety.item, variety, size, quality })
            })
          })
        } else {
          productsData.push({
            item: variety.item,
            variety,
            size: null as unknown as Size,
            quality: null as unknown as Quality
          })
        }
      })

      const filterByStartsWith = productsData.filter((product: Product) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = product
        return (
          item.name.toLowerCase().startsWith(text) ||
          variety.name.toLowerCase().startsWith(text) ||
          size?.name.toLowerCase().startsWith(text) ||
          quality?.name.toLowerCase().startsWith(text)
        )
      })
      const filterByInclude = productsData.filter((product: Product) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = product
        return (
          !(
            item.name.toLowerCase().startsWith(text) ||
            variety.name.toLowerCase().startsWith(text) ||
            size?.name.toLowerCase().startsWith(text) ||
            quality?.name.toLowerCase().startsWith(text)
          ) &&
          (item.name.toLowerCase().includes(text) ||
            variety.name.toLowerCase().includes(text) ||
            size?.name.toLowerCase().includes(text) ||
            quality?.name.toLowerCase().includes(text))
        )
      })
      const filterProductsData = [...filterByStartsWith, ...filterByInclude]

      const products =
        treeviewLevel.value === 2
          ? groupTwoLevel(filterProductsData, [], ['itemName'])
          : groupMultiLevel(filterProductsData, [], ['itemName', 'varietyName', 'qualitySizeName'])
      return mappingStock(
        products,
        {
          orderDetails: orderDetails.value,
          assignments: assignments.value,
          harvestResults: harvestResults.value,
          packingResults: packingResults.value
        },
        treeviewLevel.value === 2
      ) as Array<MultiLevelProduct>
    })
    const orderDetailsByProductList = computed(() => {
      const filterByStartsWith = orderDetails.value.filter((orderDetail: PackingOrderDetail) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = orderDetail
        return (
          item.name.toLowerCase().startsWith(text) ||
          variety.name.toLowerCase().startsWith(text) ||
          size.name.toLowerCase().startsWith(text) ||
          quality.name.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = orderDetails.value.filter((orderDetail: PackingOrderDetail) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = orderDetail
        return (
          !(
            item.name.toLowerCase().startsWith(text) ||
            variety.name.toLowerCase().startsWith(text) ||
            size.name.toLowerCase().startsWith(text) ||
            quality.name.toLowerCase().startsWith(text)
          ) &&
          (item.name.toLowerCase().includes(text) ||
            variety.name.toLowerCase().includes(text) ||
            size.name.toLowerCase().includes(text) ||
            quality.name.toLowerCase().includes(text))
        )
      })
      const filterOrderDetails = [...filterByStartsWith, ...filterByIncludes]

      const groupOrders =
        treeviewLevel.value === 2
          ? groupTwoLevel(
              filterOrderDetails,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['itemName']
            )
          : groupMultiLevel(
              filterOrderDetails,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['itemName', 'varietyName', 'qualitySizeName']
            )

      return mappingStock(
        groupOrders,
        {
          packingResults: packingResults.value,
          harvestResults: harvestResults.value
        },
        treeviewLevel.value === 2,
        ORDER_DES
      ) as Array<MultiLevelProduct>
    })
    const orderDetailsByCustomerList = computed(() => {
      const filterByStartsWith = orderDetails.value.filter((orderDetail: PackingOrderDetail) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = orderDetail
        return (
          orderDetail.customerName.toLowerCase().startsWith(text) ||
          item.name.toLowerCase().startsWith(text) ||
          variety.name.toLowerCase().startsWith(text) ||
          size.name.toLowerCase().startsWith(text) ||
          quality.name.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = orderDetails.value.filter((orderDetail: PackingOrderDetail) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = orderDetail
        return (
          !(
            orderDetail.customerName.toLowerCase().startsWith(text) ||
            item.name.toLowerCase().startsWith(text) ||
            variety.name.toLowerCase().startsWith(text) ||
            size.name.toLowerCase().startsWith(text) ||
            quality.name.toLowerCase().startsWith(text)
          ) &&
          (orderDetail.customerName.toLowerCase().includes(text) ||
            item.name.toLowerCase().includes(text) ||
            variety.name.toLowerCase().includes(text) ||
            size.name.toLowerCase().includes(text) ||
            quality.name.toLowerCase().includes(text))
        )
      })
      const filterOrderDetails = [...filterByStartsWith, ...filterByIncludes]

      const groupOrders =
        treeviewLevel.value === 2
          ? groupTwoLevel(
              filterOrderDetails,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['customerName']
            )
          : groupMultiLevel(
              filterOrderDetails,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['customerName', 'itemName', 'varietyName']
            )

      return mappingStock(
        groupOrders,
        {
          packingResults: packingResults.value,
          harvestResults: harvestResults.value
        },
        treeviewLevel.value === 2,
        ORDER_DES
      ) as Array<MultiLevelCustomer>
    })

    const goToPackingResultDetail = (
      detailId: number | null = null,
      defaultItem: number | null = null,
      defaultVariety: number | null = null,
      defaultSize: number | null = null,
      defaultQuality: number | null = null,
      orderDetailIds: string | null = '',
      isDuplicated: boolean | null = false,
      packingImportId: number | null = null
    ): void => {
      if (showBy.value === OPTION_KEY_ORDER || showBy.value === OPTION_KEY_CUSTOMER) {
        if (orderDetailIds === '') {
          const filterOrderDetails = orderDetails.value.filter((detail) => {
            const checkItem = defaultItem ? detail.item.id === defaultItem : true
            const checkVariety = defaultVariety ? detail.variety.id === defaultVariety : true
            const checkSize = defaultSize ? detail.size.id === defaultSize : true
            const checkQuality = defaultQuality ? detail.quality.id === defaultQuality : true
            return checkItem && checkVariety && checkSize && checkQuality
          })

          filterOrderDetails.forEach((orderDetail) => {
            // eslint-disable-next-line no-param-reassign
            orderDetailIds =
              orderDetailIds !== '' ? `${orderDetailIds},${orderDetail.id}` : String(orderDetail.id)
          })
        }
      }
      $router
        .push({
          name: urlPath.PACKING_RESULT_DETAIL.name,
          params: {
            detailId: detailId ? detailId.toString() : '0',
            // default packedDate if create new packing result
            defaultPackedDate: packedDateEnd.value,
            defaultAuctionDate: auctionDate.value,
            defaultItem: defaultItem !== null ? defaultItem.toString() : '',
            defaultVariety: defaultVariety !== null ? defaultVariety.toString() : '',
            defaultSize: defaultSize !== null ? defaultSize.toString() : '',
            defaultQuality: defaultQuality !== null ? defaultQuality.toString() : '',
            orderDetailIds: orderDetailIds !== null ? orderDetailIds : '',
            isDuplicated: isDuplicated ? '1' : '0',
            packingImportId: packingImportId !== null ? packingImportId.toString() : ''
          },
          query: { called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }
    const getSettingLevel = async (): Promise<void> => {
      treeviewLevel.value =
        Number(
          $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
        ) || 4
    }
    const goToDate = async (enableScrollToPreviousPosition: boolean) => {
      // get position before reload
      const positionY = window.scrollY

      await getSettingLevel()
      $router
        .replace({
          name: urlPath.PACKING_RESULT_DATE.name,
          query: {
            aucDate: auctionDate.value,
            pkDateStart: packedDateStart.value,
            pkDateEnd: packedDateEnd.value,
            called: 'true'
          }
        })
        .catch((err) => {
          // Ignore the vuex err regarding navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            console.log(err)
          }
        })
      await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
      const latestSelectedDates = {
        auctionDate: auctionDate.value.toString(),
        packingDateRange: [packedDateStart.value, packedDateEnd.value]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)

      // scroll to previous position
      if (enableScrollToPreviousPosition) {
        window.scrollTo(0, positionY)
      }
    }

    const updatePanelState = (mutation: string, panelState: Record<string, unknown>) => {
      $store.commit(mutation, panelState)
    }

    const getChooseDetail = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      const defaultItem = ref<Master | null>(null)
      const defaultVariety = ref<Master | null>(null)
      const defaultSize = ref<Master | null>(null)
      const defaultQuality = ref<Master | null>(null)

      if (
        showBy.value === OPTION_KEY_PACKING_RESULT ||
        showBy.value === OPTION_KEY_PRODUCT ||
        showBy.value === OPTION_KEY_ORDER
      ) {
        let data: Array<MultiLevelProduct>
        if (showBy.value === OPTION_KEY_PACKING_RESULT) {
          data = packingResultList.value as Array<MultiLevelProduct>
        } else if (showBy.value === OPTION_KEY_PRODUCT) {
          data = productsList.value as Array<MultiLevelProduct>
        } else {
          data = orderDetailsByProductList.value as Array<MultiLevelProduct>
        }

        defaultItem.value = data[rootIndex].children[0].children[0].children[0].item
        if (firstIndex !== null) {
          defaultVariety.value =
            data[rootIndex].children[firstIndex].children[0].children[0].variety
        }
        if (secondIndex !== null) {
          defaultSize.value =
            data[rootIndex].children[Number(firstIndex)].children[secondIndex].children[0].size

          defaultQuality.value =
            data[rootIndex].children[Number(firstIndex)].children[secondIndex].children[0].quality
        }
      } else {
        if (firstIndex !== null) {
          const currentRow =
            orderDetailsByCustomerList.value[rootIndex].children[firstIndex].children[0].children[0]
          defaultItem.value = (currentRow as PKMultiLevelQualitySize).item
        }
        if (secondIndex !== null) {
          const currentRow =
            orderDetailsByCustomerList.value[rootIndex].children[Number(firstIndex)].children[
              secondIndex
            ].children[0]
          defaultVariety.value = (currentRow as PKMultiLevelQualitySize).variety
        }
      }

      return { defaultItem, defaultVariety, defaultSize, defaultQuality }
    }

    const openActionSheet = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      isShowActionOptions.value = true
      selectedLevel.rootIndex = rootIndex
      selectedLevel.firstIndex = firstIndex
      selectedLevel.secondIndex = secondIndex

      const { defaultItem, defaultVariety, defaultSize, defaultQuality } = getChooseDetail(
        rootIndex,
        firstIndex,
        secondIndex
      )
      if (defaultItem.value) {
        actionOptions.value.defaultItem = defaultItem.value.id as number
      }

      if (defaultVariety.value) {
        actionOptions.value.defaultVariety = defaultVariety.value.id as number
      }

      if (defaultSize.value) {
        actionOptions.value.defaultSize = defaultSize.value.id as number
      }

      if (defaultQuality.value) {
        actionOptions.value.defaultQuality = defaultQuality.value.id as number
      }
    }

    const updatePackedDateStart = () => {
      if (packedDateStart.value > packedDateEnd.value) {
        packedDateEnd.value = packedDateStart.value
      }
    }

    const updateShowBy = async (): Promise<void> => {
      // save state
      updatePanelState('setShowByPackingResult', showBy.value)

      // load data
      await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
    }

    const { getExpandPanelState, getCollapsePanelState } = useExpansion()

    const currentDataState = computed(() => {
      let data: Array<any> = []
      let action = ''
      let keys: Array<string> = []
      let state = {}

      switch (showBy.value) {
        case OPTION_KEY_PACKING_RESULT:
          data = packingResultList.value
          action = 'setByPackingResultPanelState'
          keys = ['itemName', 'varietyName']
          state = byPackingResultPanelState.value
          break
        case OPTION_KEY_PRODUCT:
          data = productsList.value
          action = 'setByProductPanelState'
          keys = ['itemName', 'varietyName']
          state = byProductPanelState.value
          break
        case OPTION_KEY_ORDER:
          data = orderDetailsByProductList.value
          action = 'setByOrderPanelState'
          keys = ['itemName', 'varietyName']
          state = byOrderPanelState.value
          break
        case OPTION_KEY_CUSTOMER:
          data = orderDetailsByCustomerList.value
          action = 'setByCustomerPanelState'
          keys = ['customerName', 'itemName']
          state = byCustomerPanelState.value
          break
        case OPTION_KEY_HISTORY:
          data = packingResultList.value
          action = 'setByPackingResultPanelState'
          keys = []
          state = {}
          break
        default:
      }
      return { data, action, keys, state }
    })

    const expandAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getExpandPanelState(argument))
    }

    const collapseAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getCollapsePanelState(argument))
    }

    const deleteDetail = async (): Promise<boolean> => {
      try {
        await api.delete(`${endpoints.PACKING_RESULTS}${selectedPackingResultId.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      }
      return false
    }

    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteDetail()
      }
      if (success) {
        goToDate(true)
      }
      showDeleteDialog.value = false
    }

    const handleSearchInput = debounce((value: string) => {
      if (value !== searchInfo.value) {
        searchInfo.value = value

        delay(
          () => {
            $router
              .replace({
                query: {
                  aucDate: auctionDate.value,
                  pkDateEnd: packedDateEnd.value,
                  searchStr: searchInfo.value,
                  called: 'true'
                }
              })
              .catch((e) => {
                console.log(e)
              })
          },
          100,
          true
        )
      }
    }, 500)

    const isShowByBox: Ref<boolean> = ref(false)
    const isShowTotal: Ref<boolean> = ref(true)
    const isShowRemaining: Ref<boolean> = ref(false)
    const isShowSetting: Ref<boolean> = ref(false)
    const getSettingDefault = async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.COMMON}get_setting`)
        const settingMember = response.data.setting.packing
        isShowByBox.value = settingMember?.is_show_by_box || false
        isShowTotal.value = settingMember ? settingMember.is_show_total : true
        isShowRemaining.value = settingMember?.is_show_remaining || false
        treeviewLevel.value = settingMember?.is_has_two_level ? 2 : 4
      } catch (e) {
        showError(e, $toast, root.$t('setting.error.get_default_setting_fail') as string)
      }
    }
    const updateSetting = (
      isShowTotalValue: boolean,
      isShowByBoxValue: boolean,
      isShowRemainingValue: boolean
    ) => {
      isShowByBox.value = isShowByBoxValue
      isShowTotal.value = isShowTotalValue
      isShowRemaining.value = isShowRemainingValue
      isShowSetting.value = false
      // update state
      $store.commit('setShowValuePacking', {
        isShowByBox: isShowByBoxValue,
        isShowTotal: isShowTotalValue,
        isShowRemaining: isShowRemainingValue
      })
    }

    const setDate = (startDate: string, endDate: string) => {
      packedDateStart.value = startDate
      packedDateEnd.value = endDate
    }

    onMounted(async () => {
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        ) &&
        !isFromCalendar
      )
        await getNextAuctionDate()
      const showValueState = $store.state.packingResult.showValue
      if (Object.keys(showValueState).length) {
        isShowByBox.value = showValueState.isShowByBox
        isShowRemaining.value = showValueState.isShowRemaining
        isShowTotal.value = showValueState.isShowTotal
      } else {
        await getSettingDefault()
      }
      await getSettingLevel()
      await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
    })
    const editPackingImport = (packingImportId: number, growerId: number) => {
      $router
        .push({
          name: urlPath.PACKING_IMPORT_FORM.name,
          params: {
            detailId: packingImportId.toString()
          },
          query: {
            called: 'true',
            is_update_packing_result: 'true',
            grower_id: growerId.toString()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
    const goToUpdatePacking = (packingImportId: number, growerId: number) => {
      if (packingImportId !== 0) editPackingImport(packingImportId, growerId)
      else
        goToPackingResultDetail(
          selectedPackingResultId.value,
          null,
          null,
          null,
          null,
          null,
          null,
          packingImportId
        )
    }
    const editPacking = () => {
      isShowActionOptions.value = false
      const selectedPackingResult = packingResults.value.find(
        (packing: any) => packing.id === selectedPackingResultId.value
      )
      try {
        showSelectPacking.value = selectedPackingResult
          ? selectedPackingResult.packingImportDetails?.length > 1
          : false
        if (!showSelectPacking.value) {
          goToPackingResultDetail(selectedPackingResultId.value)
        }
      } catch {
        goToPackingResultDetail(selectedPackingResultId.value)
      }
    }
    const getPackingImports = (): Array<any> => {
      let packingImports: Array<any> = []
      const selectedPackingResult = packingResults.value.find(
        (packing: any) => packing.id === selectedPackingResultId.value
      )
      try {
        packingImports = selectedPackingResult
          ? selectedPackingResult.packingImportDetails.map((packingImport: any) => {
              return {
                id: packingImport.id,
                grower: packingImport.grower
              }
            })
          : ([] as Array<any>)
      } catch {
        packingImports = []
      }
      return packingImports
    }
    const getGrowerInfo = (item: any): string => {
      let icon = ''
      if (item.packingImportDetails) {
        if (item.packingImportDetails.length > 1) {
          icon = 'mdi-account-multiple'
        } else {
          icon = 'mdi-account'
        }
      }
      return icon
    }

    return {
      dateRange,
      auctionDate,
      packedDateStart,
      packedDateEnd,
      auctionDateModal,
      packedDateStartModal,
      packedDateEndModal,
      goToDate,
      packingResults,
      goToPackingResultDetail,
      loading,
      showBy,
      showByOptions,
      byPackingResultPanelState,
      byProductPanelState,
      byOrderPanelState,
      byCustomerPanelState,
      packingResultsByHistory,
      packingResultList,
      productsList,
      orderDetailsByProductList,
      orderDetailsByCustomerList,
      actionOptions,
      updatePanelState,
      openActionSheet,
      updatePackedDateStart,
      expandAll,
      collapseAll,
      updateShowBy,
      OPTION_KEY_PACKING_RESULT,
      OPTION_KEY_PRODUCT,
      OPTION_KEY_ORDER,
      OPTION_KEY_CUSTOMER,
      OPTION_KEY_HISTORY,
      isShowActionOptions,
      handleSearchInput,
      searchInfo,
      selectedPackingResultId,
      isShowByBox,
      isShowTotal,
      isShowRemaining,
      isShowSetting,
      updateSetting,
      setDate,
      getNextAuctionDate,
      treeviewLevel,
      deleteDetail,
      confirmDelete,
      showDeleteDialog,
      showSelectPacking,
      goToUpdatePacking,
      editPacking,
      getPackingImports,
      getGrowerInfo
    }
  }
})

export default PackingResultDate
